import React from "react";
import Footer from "../../components/Footer";
import "./Profile.css";

export default function Profile() {
  return (
    <div>
      <div className="wrapperforblog">
        <div className="container text-center">
          <div className="blogheadforProfile">
            <img src="Img/Monish.jpg" className="ImgRound" alt="Profile" />
            <h1 className="ProfileName">MONISH SAI</h1>
            <h1 className="ProfileName1">IS A DEVELOPER</h1>
          </div>
        </div>
        <div>
          <h1 className="ProfileName1">ABOUT</h1>
          <p className="FontSize1">
            Monish is a web design and development virtuoso, adept at crafting
            stunning webpages and mastering intricate development tasks with
            seamless front-end finesse and back-end prowess. Beyond coding, He
            is an avid runner, fueled by a passion for fitness and endurance.
            Always plugged into the latest tech updates, He thrives on social
            engagement, exchanging ideas, and staying ahead in the dynamic
            digital landscape. He embodies a rare blend of technical expertise,
            creative flair, and an unwavering commitment to excellence in both
            professional and personal endeavors. Monish also loves discussing
            current updates on codes and technologies, keeping abreast of
            industry advancements.
          </p>
          <h1 className="ProfileName1">RECENT BLOG</h1>
        </div>

        <Footer />
        {/* Here after make diffenet files and keep on adding as a component :)  */}
      </div>
    </div>
  );
}
