import React from "react";
import Smallline from "../../components/Smallline";
import "./Blog.css";
import FirebaseLink from "../../BlogPostsLinks/FirebaseLink";
import Footer from "../../components/Footer";
// import VerySmallLine from "../../components/VerySmallLine";

export default function Blog() {
  return (
    <div>
      <div className="wrapperforblog">
        <div className="container">
          <h1 className="bloghead">BLOG</h1>
        </div>
        <Smallline />
        <div className="Subscribe">
          <img src="Img/Ticket.png" alt="Add" height="25px" width="25px" />
          <h4 className="words">Subscribe to this blog</h4>
        </div>
        <Smallline />
        <FirebaseLink />

        {/* <FirebaseLink /> */}
        <Footer />
        {/* Here after make diffenet files and keep on adding as a component :)  */}
      </div>
    </div>
  );
}
