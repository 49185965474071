import React from "react";
import Smallline from "./Smallline";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div>
      <footer>
        <div className="pt-5"></div>
        <Smallline />

        <div className="container- PaDd">
          <div className="row">
            <div className="col-md-8">
              <h6 className="FindME">FIND MONISH ON INTERNET</h6>
              <ul className="unClass1">
                <li className="displayF">
                  {" "}
                  <img
                    src="Img/Github.png"
                    alt="github"
                    width="24px"
                    height="24px"
                  />{" "}
                  <p className="FootClass">
                    <a
                      href="https://github.com/monishsai4"
                      className="unClass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Github
                    </a>{" "}
                  </p>{" "}
                </li>
                <li className="displayF">
                  {" "}
                  <img
                    src="Img/Linkedin.png"
                    alt="github"
                    width="24px"
                    height="24px"
                  />{" "}
                  <p className="FootClass">
                    <a
                      href="https://www.linkedin.com/in/monish-sai/"
                      className="unClass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Linkedin
                    </a>{" "}
                  </p>{" "}
                </li>
                <li className="displayF">
                  {" "}
                  <img
                    src="Img/Dev.png"
                    alt="github"
                    width="24px"
                    height="24px"
                  />{" "}
                  <p className="FootClass">
                    <a
                      href="/"
                      className="unClass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Dev
                    </a>
                  </p>{" "}
                </li>
                <li className="displayF">
                  {" "}
                  <img
                    src="Img/Twitter.png"
                    alt="github"
                    width="24px"
                    height="24px"
                  />{" "}
                  <p className="FootClass">
                    <a
                      href="/"
                      className="unClass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Twitter
                    </a>
                  </p>{" "}
                </li>
                <li className="displayF">
                  {" "}
                  <img
                    src="Img/Instagram.png"
                    alt="github"
                    width="24px"
                    height="24px"
                  />{" "}
                  <p className="FootClass">
                    {" "}
                    <a
                      href="/"
                      className="unClass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Instagram
                    </a>
                  </p>{" "}
                </li>
              </ul>
            </div>
            <div className="col-md-4 ">
              <div className="displayF">
                <img
                  src="Img/Copyright.png"
                  alt="Copyright"
                  width="20px"
                  height="20px"
                />{" "}
                <p className="FootClass1">
                  {" "}
                  <a href="/" className="unClass">
                    2024 <span> Monish</span>
                  </a>
                </p>{" "}
              </div>
              <div>
                <Link to="/" className="text-decor">
                  Blogs
                </Link>{" "}
                |{" "}
                <Link to="/lInks" className="text-decor">
                  Links
                </Link>{" "}
                |{" "}
                <Link to="/Contact" className="text-decor">
                  Contact
                </Link>{" "}
                |{" "}
                <Link to="/Profile" className="text-decor">
                  profile
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
