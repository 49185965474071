import React from "react";
import { Link } from "react-router-dom";
import "./Links.css";
export const Links = () => {
  return (
    <div>
      <div className="wrapperforblog">
        <div className="container">
          <h1 className="bloghead">LINKS</h1>
        </div>
        <p className="FontSize">Find and follow Monish on the internet:</p>
        <ul className="unClass1">
          <li className="displayF">
            {" "}
            <img
              src="Img/Github.png"
              alt="github"
              width="24px"
              height="24px"
            />{" "}
            <p className="FootClasslink">
              <a
                href="https://github.com/monishsai4"
                className="unClass"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>{" "}
            </p>{" "}
          </li>
          <li className="displayF">
            {" "}
            <img
              src="Img/Linkedin.png"
              alt="github"
              width="24px"
              height="24px"
            />{" "}
            <p className="FootClasslink">
              <a
                href="https://www.linkedin.com/in/monish-sai/"
                className="unClass"
                target="_blank"
                rel="noopener noreferrer"
              >
                Linkedin
              </a>{" "}
            </p>{" "}
          </li>
          <li className="displayF">
            {" "}
            <img
              src="Img/Dev.png"
              alt="github"
              width="24px"
              height="24px"
            />{" "}
            <p className="FootClasslink">
              <a
                href="https://dev.to/saiii_0428"
                className="unClass"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Dev
              </a>
            </p>{" "}
          </li>
          <li className="displayF">
            {" "}
            <img
              src="Img/Twitter.png"
              alt="github"
              width="24px"
              height="24px"
            />{" "}
            <p className="FootClasslink">
              <a
                href="https://x.com/MonishSai4"
                className="unClass"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                Twitter
              </a>
            </p>{" "}
          </li>
          <li className="displayF">
            {" "}
            <img
              src="Img/Instagram.png"
              alt="github"
              width="24px"
              height="24px"
            />{" "}
            <p className="FootClasslink">
              {" "}
              <a
                href="https://www.instagram.com/saiii_0428/"
                className="unClass"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </p>{" "}
          </li>
        </ul>
      </div>
      <div className="wrapper">
        <div className="displayF">
          <img
            src="Img/Copyright.png"
            alt="Copyright"
            width="20px"
            height="20px"
          />{" "}
          <p className="FootClass1">
            {" "}
            <a href="/" className="unClass">
              2024 <span> Monish</span>
            </a>
          </p>{" "}
        </div>
        <div>
          <Link to="/" className="text-decor">
            Blogs
          </Link>{" "}
          -{" "}
          <Link to="/links" className="text-decor">
            Links
          </Link>{" "}
          -{" "}
          <Link to="/Contact" className="text-decor">
            Contact
          </Link>{" "}
          -{" "}
          <Link to="/Profile" className="text-decor">
            profile
          </Link>
        </div>
      </div>
    </div>
  );
};
