import React from "react";
import "./Components.css";

export default function Smallline() {
  return (
    <>
      <div className="Smallline"></div>
    </>
  );
}
