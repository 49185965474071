import React from "react";
import "./Components.css";

export default function VerySmallLine() {
  return (
    <>
      {" "}
      <div className="VerySmallLine"></div>
    </>
  );
}
