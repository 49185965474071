import React from "react";
import "./FirebasePost.css";
import Smallline from "../components/Smallline";
import Footer from "../components/Footer";

export default function FirebasePost() {
  return (
    <>
      <div className="wrapper">
        <header>
          <div className="text-center wrapperforblog">
            <h1 className="Give-heading-weight">
              UPLOADING YOUR REACT PROJECT TO FIREBASE: A STEP BY STEP GUIDE
            </h1>
          </div>
          <img
            src="Img/FirebaseReact.png"
            alt="FirebaseReact"
            width="100%"
            height="auto"
            className="pt-5"
          />
        </header>
        <div className="mt-5">
          <Smallline />
          <p className="mt-3">
            Published on June 26, 2024 , updated on June 28, 2024 by Monish
          </p>
          <Smallline />
        </div>
        <div className="mt-4 ">
          <div className="Halfing-div">
            <p className=" FontSize">
              If you already have built a React Project, then you can skip this
              method. To start/Build React project , we need to install node
              from{" "}
              <a
                href="https://nodejs.org/en/download/prebuilt-installer"
                target="_blank"
                rel="noopener noreferrer"
                className=" FontSize"
              >
                Node.js
              </a>
              , Then by creating a project file and open it with code editor
            </p>

            <p className="FontSize">then opening project terminal:</p>
            <p className="Black-background">
              <span className="SpanCode">npx create-react-app myApp</span>
            </p>
            <p className=" FontSize">
              To see the custom react app built locally:
            </p>
            <p className="Black-background">
              <span className="SpanCode">cd myApp</span>
            </p>
            <p className="Black-background">
              <span className="SpanCode">npm start</span>
            </p>
            <h5 className="HeadFont">What is Firebase?</h5>
            <p className="FontSize pt-2">
              Firebase is a comprehensive platform for building web and mobile
              applications developed by{" "}
              <span className="SpanColour">Google</span>. It offers a suite of
              tools and services that empower developers to swiftly develop
              high-quality apps, handling tasks like authentication, real-time
              databases, cloud storage, and hosting.
            </p>
            <p className="FontSize pt-2">
              Firebase's <span className="SpanColour">real-time database</span>{" "}
              enables synchronized data updates across clients in milliseconds,
              while its authentication services provide secure user management.
              Additionally, Firebase integrates smoothly with other Google
              services and offers robust analytics to track app performance and
              user behavior.
            </p>
            <p className="FontSize pt-2">
              {" "}
              Its <span className="SpanColour">scalability</span> and ease of
              use make it a popular choice among developers for creating dynamic
              and responsive applications.
            </p>
            <img
              src="Img/Firebase1.png"
              alt="Firebase1"
              width="100%"
              height="auto"
              // className="pt-5"
            />
            <h5 className="HeadFont">How do you use Firebase?</h5>
            <p className="FontSize">
              You can access the firebase page by clicking this link:
            </p>
            <p className="Black-background">
              <span className="SpanCode">
                <a
                  href="https://firebase.google.com/products/app-hosting"
                  target="_blank"
                  className="Anchor"
                  rel="noopener noreferrer"
                >
                  firebase.google.com/products/app-hosting
                </a>
              </span>
            </p>
            <p className="FontSize pt-2 ">
              {" "}
              Click on getting started for Web/App hosting and Login:
            </p>

            <p className="FontSize pt-2 px-2">
              {" "}
              Step 1 of 3: Enter your{" "}
              <span className="SpanColour">Project Name</span>.
            </p>
            <p className="FontSize pt-2 px-2">
              {" "}
              Step 2 of 3: Enable
              <span className="SpanColour"> Google Analytics</span> for this
              project.
            </p>
            <p className="FontSize pt-2 px-2">
              {" "}
              Step 3 of 3: Select your Analytics location preferably nearby and
              create project.
            </p>
            <img
              src="Img/Firebase2.png"
              alt="Firebase2"
              width="100%"
              height="auto"
              // className="pt-5"
            />
            <p className="FontSize pt-2 ">
              {" "}
              You can see different dialog boxes, you can explore if you want
              to: will write a better para for this one leaving this just as of
              now
            </p>
            <p className="FontSize pt-2 ">
              Follow these images for further information:
            </p>
            <img
              src="Img/Firebase3.png"
              alt="Firebase2"
              width="100%"
              height="auto"
              // className="pt-5"
            />

            <h5 className="HeadFont">Set up firebase hosting</h5>
            <p className="FontSize1 pt-1 ">
              All these below codes should be run on terminal of vscode
              <i>
                {" "}
                (Assuming your making your app in vscode / run these in any
                terminal inside your project){" "}
              </i>
            </p>
            <h6 className="SubHeadFont">Install Firebase CLI</h6>
            <p className="FontSize1 pt-1 ">
              To host your site with Firebase Hosting, you need the Firebase CLI
              (a command line tool). Run the following npm command to install
              the CLI or update to the latest CLI version.
            </p>
            <p className="Black-background">
              <span className="SpanCode">npm install -g firebase-tools</span>
            </p>
            <h6 className="SubHeadFont">Initialise your project</h6>
            <p className="FontSize1 pt-1 ">
              Open a terminal window and navigate to or create a root directory
              for your web app
            </p>
            <p className="FontSize1 pt-1 ">
              <i>Sign in to Google</i>
            </p>
            <p className="Black-background">
              <span className="SpanCode">firebase login</span>
            </p>
            <p className="FontSize1 pt-1 ">
              <i>Initiate your project</i>
            </p>
            <p className="Black-background">
              <span className="SpanCode">firebase init</span>
            </p>
            <h6 className="SubHeadFont">Run the development server</h6>
            <p className="FontSize1 pt-1 ">Running the build command</p>
            <p className="FontSize1 pt-1 ">
              <i>
                The npm run build command is used to create a production-ready
                version of your application.
              </i>
            </p>
            <p className="Black-background">
              <span className="SpanCode">npm run build</span>
            </p>
            <h6 className="SubHeadFont">Deploy to firebase hosting</h6>
            <p className="FontSize1 pt-1 ">
              When you're ready, deploy your web app
            </p>
            <p className="FontSize1 pt-1 ">
              <i>
                Put your static files (e.g. HTML, CSS, JS) in your app's deploy
                directory (the default is 'public'). Then, run this command from
                your app's root directory:
              </i>
            </p>
            <p className="Black-background">
              <span className="SpanCode">firebase deploy</span>
            </p>
            <p className="FontSize1 pt-1 ">
              Your website is ready! copy the link and send it your friends
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
