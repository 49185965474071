import React, { useState } from "react";
import Footer from "../../components/Footer";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const handleSubmit = (e) => {
    console.log("Hello");
    e.preventDefault();
    const serviceId = "service_f7gwdqg";
    const publicId = "template_tpqxurs";
    const publicKey = "a8ENFXD7P58DsU2yn";
    const templateParams = {
      from_name: name,
      from_subject: subject,
      from_email: email,
      message: message,
    };
    if (name === "" || email === "" || subject === "" || message === "") {
      toast.warn("Enter all fields!");
    } else {
      emailjs
        .send(serviceId, publicId, templateParams, publicKey)
        .then((response) => {
          console.log("email sent successfully", response);
          toast.success("Message submitted!");
          setEmail("");
          setMessage("");
          setName("");
          setSubject("");
        })
        .catch((error) => {
          console.error("Error sending email", error);
        });
    }
  };
  return (
    <div>
      <div className="wrapperforblog">
        <div className="container">
          <h1 className="bloghead">CONTACT</h1>
        </div>
        <p className="FontSize">
          You can Email Monish here or catch him on any of these social
          networks.
        </p>
        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">
            Name
          </label>
          <input
            type="text"
            className="form-control"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="your name"
          />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="name@example.com"
          />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlInput1" className="form-label">
            Subject
          </label>
          <input
            type="text"
            className="form-control"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            placeholder="Hi monish!👋🏼
"
          />
        </div>
        <div className="mb-3">
          <label for="exampleFormControlTextarea1" className="form-label">
            Your message
          </label>
          <textarea
            className="form-control"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="5"
            placeholder="message"
          ></textarea>
        </div>
        <button
          className="btn BgColor"
          type="submit"
          id="toastbtn"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <ToastContainer />

        <Footer />
        {/* Here after make diffenet files and keep on adding as a component :)  */}
      </div>
    </div>
  );
};
