import "./App.css";
import { Routes, Route, Link } from "react-router-dom";
import Top from "./components/Top";
import Blog from "./MainFour/Blog/Blog";
import FirebasePost from "./BlogPosts/FirebasePost";
import { Contact } from "./MainFour/Contact/Contact";
import { Links } from "./MainFour/Links/Links";
import Profile from "./MainFour/Profile/Profile";
// import News from "./MainFour/News/News";

function App() {
  // const Happenme = (e) => {
  //   e.preventdefault();
  // };

  return (
    <>
      <Top />
      <div className="wrapper">
        <nav className="navbar navbar-expand-md ">
          <div className="container-fluid">
            <a className="navbar-brand GiveWeight" href="/Profile">
              MONISH SAI
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNavAltMarkup"
            >
              <div className="navbar-nav">
                <Link
                  to="/"
                  className="GiveWeightNav nav-link "
                  // onClick={Happenme}
                >
                  BLOGS
                </Link>
                {/* <Link to="/" className="GiveWeightNav nav-link ">
                  NEWS
                </Link> */}
                <Link
                  to="/lInks"
                  className="GiveWeightNav nav-link "
                  // onClick={Happenme}
                >
                  LINKS
                </Link>
                <Link to="/Contact" className="GiveWeightNav nav-link ">
                  CONTACT
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Routes>
        <Route path="/" element={<Blog />} />
        <Route path="/FirebasePost" element={<FirebasePost />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Links" element={<Links />} />
        <Route path="/Profile" element={<Profile />} />
      </Routes>
    </>
  );
}

export default App;
