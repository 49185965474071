import React from "react";
import "./Components.css";

export default function Top() {
  return (
    <>
      <div className="TopFirst"></div>
    </>
  );
}
