import React from "react";
import VerySmallLine from "../components/VerySmallLine";
import { Link } from "react-router-dom";

export default function FirebaseLink() {
  return (
    <>
      <div className="FirebasePad">
        <div className="container-fluid">
          <Link to="/FirebasePost" className="text-decor">
            <img
              src="Img/FirebaseReact.png"
              alt="FirebaseReact"
              width="100%"
              height="auto"
            />
            <h3 className="PositionHeading">
              Uploading Your React Project to Firebase: A Step-by-Step Guide
            </h3>
          </Link>
        </div>

        <p className="PublishWriting">
          Published on June 22, 2024 , updated on June 22, 2024 by Monish Sai
        </p>
        <div className="Publishwriting-content">
          <h6 className="Publishwriting-text">
            In this tutorial, you will learn how to seamlessly deploy your React
            project to Firebase hosting. We'll walk through the entire process
            from setting up Firebase for your project to configuring Firebase
            hosting, optimizing for performance, and deploying your application.
            By the end, you'll have your React application up and running live
            on the web with Firebase
          </h6>
        </div>
      </div>
      <VerySmallLine />
    </>
  );
}
